<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div
      class="container-fluid mt--6"
      :class="{
        validateClient: !all_loaded,
        form_laive: !all_loaded,
      }"
    >
      <div class="box_loader">
        <div class="loader"></div>
      </div>
      <div class="form_laive pb-4 mb-4" v-if="all_loaded">
        <div
          v-for="(bonificacion, index) in bonificaciones"
          :key="bonificacion"
        >
          <CustomCard
            :bonificacion="bonificacion"
            :tipos="tipos"
            :index="index"
          ></CustomCard>
        </div>

        <div class="row w-100 mx-0 mb-4">
          <base-button
            type="success"
            class="bg-white text-laive py-1 bonificacion-text"
            style="height: 32px"
            @click.prevent="agregarBonificacion"
          >
            <i class="fas fa-plus-circle"></i>
            Agregar bonificación
          </base-button>
        </div>
      </div>
    </div>

    <div
      class="row px-4 py-2 mx-0 mt-2 w-100 position-fixed bg-white footer-buttons"
    >
      <base-button
        type="success"
        :outline="true"
        class="ml-auto px-4 py-3 submit-btns d-flex"
        @click.prevent="regresar"
        :disabled="esta_enviando"
      >
        <span class="">Cancelar</span>
      </base-button>

      <base-button
        type="success"
        class="px-4 border-0 submit-btns d-flex"
        :class="!esta_enviando ? 'py-3' : 'py-0'"
        @click.prevent="enviarBonificaciones"
        :disabled="!todosValidos || esta_enviando"
      >
        <span class="" v-if="!esta_enviando">Enviar</span>
        <div v-else class="laive-loader"></div>
      </base-button>
    </div>
  </div>
</template>

<script setup>
import "@/assets/sass/laive/bonificaciones.scss";

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../Layout/DashboardNavbar";
import CustomCard from "./components/CustomCard.vue";
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import constantes from "@/model/constantes.js";
import Swal from "sweetalert2";

import { useStore } from "vuex";
const store = useStore();
store.dispatch("bonificacionUtil/cargarLista", "distribuidoras");
store.dispatch("bonificacionUtil/cargarLista", "productos");

store.dispatch("bonificacionUtil/cargarLista", "tiposCliente");
store.dispatch("bonificacionUtil/cargarLista", "segmentos");
store.dispatch("bonificacionUtil/cargarLista", "girosNegocio");
store.dispatch("bonificacionUtil/cargarLista", "tiposVendedor");
store.dispatch("bonificacionUtil/cargarLista", "vendedores");

store.dispatch("bonificacionUtil/cargarLista", "categorias");
store.dispatch("bonificacionUtil/cargarLista", "familias");
store.dispatch("bonificacionUtil/cargarLista", "presentaciones");

const all_loaded = computed(
  () =>
    store.state.bonificacionUtil.distribuidoras.length &&
    store.state.bonificacionUtil.productos.length &&
    store.state.bonificacionUtil.tiposCliente.length &&
    store.state.bonificacionUtil.segmentos.length &&
    store.state.bonificacionUtil.girosNegocio.length &&
    store.state.bonificacionUtil.tiposVendedor.length &&
    store.state.bonificacionUtil.vendedores.length
);

const tipos = ref([
  {
    nombre: "Seleccionar Tipo",
  },
  {
    nombre: "Promoción",
    valor: "promocion",
  },
  {
    nombre: "Combo",
    valor: "combo",
  },
  {
    nombre: "Escala",
    valor: "escala",
  },
  {
    nombre: "Rebate",
    valor: "rebate",
  },
]);

const bonificaciones = computed(() => store.state.bonificacion.bonificaciones);

onMounted(() => {
  if (!bonificaciones.value.length) {
    bonificaciones.value.push({
      indice: 0,
      tipo: constantes.TIPO_PROMOCION,
      carga_masiva: null,
      descripcion: "",
      costos_raw: [],
      inicio: new Date(),
      fin: new Date(),
      prods_promo: [{ productos: [], unidades: null }],
      prods_regalo: [
        {
          productos: [],
          unidades: null,
          tipo: constantes.TIPO_REGALO_PRODUCTO,
          valor: null,
        },
      ],
      escala_individual: false,
      distribuidoras: [],
      vendedores: [],
      tipos_cliente: [],
      segmentos: [],
      giros_negocio: [],
      tipos_vendedor: [],
      es_completa: false,
      conteo_terminado: true,
      total_carga_masiva: 0,
    });
  }
});

onBeforeUnmount(() => {
  store.commit("bonificacion/limpiar", bonificaciones);
});

const agregarBonificacion = () => {
  let ultimoIndice = bonificaciones.value.reduce(
    (max, bonificacion) =>
      bonificacion.indice > max ? bonificacion.indice : max,
    0
  );
  bonificaciones.value.push({
    indice: ultimoIndice + 1,
    tipo: "promocion",
    carga_masiva: null,
    descripcion: "",
    inicio: new Date(),
    fin: new Date(),
    prods_promo: [{ productos: [], unidades: null }],
    prods_regalo: [
      {
        productos: [],
        unidades: null,
        tipo: constantes.TIPO_REGALO_PRODUCTO,
        valor: null,
      },
    ],
    escala_individual: false,
    distribuidoras: [],
    vendedores: [],
    tipos_cliente: [],
    segmentos: [],
    giros_negocio: [],
    tipos_vendedor: [],
    es_completa: false,
    conteo_terminado: true,
  });
};

const esta_enviando = ref(false);
const regresar = () => {
  location.href = "/bonificaciones/listado";
};

const enviarBonificaciones = async () => {
  esta_enviando.value = true;
  const result = await store.dispatch("bonificacion/create");
  esta_enviando.value = false;
  let mensajes_html = "";
  if (result?.status === 200) {
    if (result.data.length > 0) {
      result.data.forEach((mensaje) => {
        mensajes_html += `<li>${mensaje}</li>`;
      });
    }
    Swal.fire({
      title: "OK",
      html: `
      <ul>
        ${mensajes_html}
        </ul>`,
      icon: "success",
    }).then((result) => {
      if (result.isDismissed || result.isConfirmed) {
        location.href = "/bonificaciones/listado";
      }
    });
  } else if (result?.status === 409) {
    Swal.fire({
      icon: "error",
      title: "Duplicado",
      text: "Ya existe una bonificación con los mismos productos",
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Algo salió mal.",
    });
  }
};
const todosValidos = computed(() => {
  return bonificaciones.value.every(
    (item) => item.es_completa == true && item.conteo_terminado == true
  );
});
</script>

<style lang="scss" scoped>
.footer-buttons {
  bottom: 0px;
  right: 0;
}
.bonificacion {
  &-tipo {
    border-radius: 5px;
    border: 1px solid #02a54f;
    color: #02a54f;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 8px;
  }
}
.text-laive {
  color: #02a54f;
}
.text-laive:hover {
  background-color: white !important;
  color: #02a54f;
}
.btn {
  border-radius: 10px !important;
}
</style>
